<template>
  <p class="title-custom-muted">{{ title }}</p>
</template>

<script>
export default {
  name: 'TitleMuted',
  props: {
    title: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>
.title-custom-muted {
  color: rgb(158, 160, 165);
  font-size: 12px !important;
  font-weight: 500;
  /* height: 18px; */
  line-height: 18px;
  text-transform: uppercase;
}
</style>