<template>
  <div class="py-2 animated fadeIn">
    <title-muted :title="$t('drivers.wallet.methods_payments')" />
    <b-list-group v-if="!load">
      <b-list-group-item
        v-for="(methodPayment, index) in listPaymentMethods"
        :key="index"
        href="#"
        @click="$bvToast.show(`paymentToast-${index}`)">
        <span
          v-if="methodPayment.type === 'credit_card'"
          class="justify-items-end"
        >
          <b-button
            v-if="notInUse(methodPayment)"
            v-b-tooltip.hover
            :title="$t('payments.labels.removePaymentMethod')"
            variant="outline-danger"
            size="sm"
            class="float-right"
            @click="inactivePaymentMethod(methodPayment.id)"
          >
            <i class="fa fa-trash" />
          </b-button>
          <i
            v-else
            class="fa fa-check float-right text-success pr-1"
            :title="$t('payments.labels.usedRecurrency'),"
          />
        </span>
        <div
          v-if="methodPayment.type === 'credit_card'"
          class="d-flex align-items-center div-item-payment">
          <div class="d-inline">
            <i class="fa fa-cc-mastercard fa-2x text-secondary" />
          </div>
          <span class="pl-3 font-weight-bolder text-uppercase">{{ methodPayment.cc_brand }} x- {{ methodPayment.cc_number }}</span>
        </div>
        <div
          v-else
          class="d-flex align-items-center div-item-payment">
          <div class="d-inline">
            <i
              v-if="methodPayment.type === 'multi_payment_method'"
              class="fa fa-money-bills fa-2x text-secondary"
            />
            <i
              v-if="methodPayment.type === 'boleto'"
              class="fa fa-barcode fa-2x text-secondary"
            />
            <img
              v-if="methodPayment.type === 'pix'"
              style="width: 35px; display: inline-block;"
              src="@assets/logos/pix.png"
            >
            <img
              v-if="methodPayment.type === 'oxxo'"
              style="width: 35px; display: inline-block;"
              src="@assets/logos/oxxo.svg"
            >
          </div>
          <span class="pl-3 font-weight-bolder text-uppercase">{{ methodPayment.type === 'multi_payment_method' ? $t('drivers.wallet.multiPaymentMethods') : methodPayment.type }}</span>
        </div>
        <b-toast :id="`paymentToast-${index}`" title="Informações" static no-auto-hide>
          <div v-if="methodPayment.type === 'credit_card'">
            <p class="text-toast-custom">
              CPF: <span class="font-weight-bold">{{ methodPayment.cc_holder_cpf ? methodPayment.cc_holder_cpf : 'Sem dados.' }} </span>
            </p>
            <p v-if="methodPayment.cc_expired_at" class="text-toast-custom">
              Data de Expiração: <span class="font-weight-bold">{{ methodPayment.cc_expired_at | moment('DD/YYYY') }}</span>
            </p>
          </div>
          <div v-else>
            <p class="text-toast-custom">
              Sem dados.
            </p>
          </div>
        </b-toast>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
import TitleMuted from '@components/shared/title-muted.vue'
import PAYMENTMETHOD_GET from '@graphql/payment/queries/get.gql';
import INACTIVE_PAYMENT_METHOD from '@graphql/payment/mutations/inactivePaymentMethod.gql';
import { makeMutation, makeQuery } from "@graphql/middleware";
export default {
  name: 'DriverPaymentMethods',
  components: {
    TitleMuted
  },
  props: {
    profile: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      listPaymentMethods: [],
      load: false
    }
  },
  async mounted () {
    await this.getPaymentMethods()
  },
  methods: {
    async getPaymentMethods () {
      this.load = true
      try {
        const { data } = await makeQuery(PAYMENTMETHOD_GET, {
          driver: this.$route.params.id,
        });
        this.listPaymentMethods = data.paymentMethods.items;
      } catch(err){
        console.error(err);
      } finally {
        this.load = false;
      }
    },
    notInUse(paymentMethod) {
      return paymentMethod.type === 'credit_card' &&
        !paymentMethod.used &&
        this.profile.booking?.transaction_method?.id !== paymentMethod.id
    },
    inactivePaymentMethod(id) {
      console.log('id-payment', id)
      const confirmationWindow = this.$swal.mixin({
        confirmButtonClass: 'btn btn-success',
        cancelButtonClass: 'btn btn-danger mr-3',
        buttonsStyling: false,
      });

      confirmationWindow({
        title: this.$t('payments.creditRequest.modalConfirmationTitle'),
        text: this.$t('payments.labels.inactivePaymentMethod'),
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('payments.buttons.confirm'),
        cancelButtonText: this.$t('payments.buttons.cancel'),
        showLoaderOnConfirm: true,
        reverseButtons: true,
      }).then(result => {
        if (result.value) {

          makeMutation(
            INACTIVE_PAYMENT_METHOD,
            { id }
          )
          .then(() => {
              this.showSuccessModal();
              this.getPaymentMethods()
            })
            .catch(() => {
              this.showErrorModal();
            });
        }
      });
    },
    showSuccessModal() {
      this.$swal({
        type: 'success',
        title: 'Ação realizada com Sucesso!',
        showConfirmButton: false,
        timer: 2500,
      })
    },
    showErrorModal() {
      this.$swal({
        type: 'error',
        title: 'Ocorreu um erro!',
        text: 'Verifique as informações novamente',
        showConfirmButton: false,
        timer: 2500,
      });
    },
  }
}
</script>

<style scoped>
.btn-border-left {
  padding-left: 0px !important;
}
.div-item-payment {
  cursor: pointer;
}
.text-toast-custom {
  font-size: 12px;
}
</style>
